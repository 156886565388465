import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { Loan } from '@dougs/loans/dto';

@Injectable({
  providedIn: 'root',
})
export class LoanHttpService {
  constructor(private readonly http: HttpClient) {}

  getLoans(companyId: number): Observable<Loan[]> {
    return this.http.get<Loan[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/loans`);
  }

  deleteLoan(loan: Loan): Observable<Loan> {
    return this.http.delete<Loan>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${loan.companyId}/loans/${loan.id}`,
    );
  }

  updateLoan(loan: Loan): Observable<Loan> {
    return this.http.post<Loan>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${loan.companyId}/loans/${loan.id}`,
      loan,
    );
  }

  addLoan(companyId: number): Observable<Loan> {
    return this.http.post<Loan>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/loans`, {
      companyId,
    });
  }

  addLoanAttachment(loan: Loan, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${loan.companyId}/loans/${loan.id}/attachments?filename=${file.name}&type=attachment`,
      formData,
    );
  }

  removeLoanAttachment(loan: Loan, attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${loan.companyId}/loans/${loan.id}/attachments/${attachment.id}`,
    );
  }
}
