import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Loan } from '@dougs/loans/dto';
import { LoanHttpService } from '../http/loan.http';

interface LoanState {
  loans: Loan[];
}

@Injectable({
  providedIn: 'root',
})
export class LoanStateService extends StateService<LoanState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly loanHttpService: LoanHttpService,
  ) {
    super();
  }

  readonly loans$: Observable<Loan[]> = this.select((state) => state.loans);

  async refreshLoans(companyId: number): Promise<void> {
    try {
      this.setState({
        loans: this.getAndSetCacheState('loans', companyId)
          ? this.state.loans
          : await lastValueFrom(this.loanHttpService.getLoans(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
      this.clearCache('loans');
    }
  }

  async deleteLoan(loan: Loan): Promise<void> {
    try {
      await lastValueFrom(this.loanHttpService.deleteLoan(loan));
      this.setState({
        loans: this.state.loans.filter((loanIterated: Loan) => loan.id !== loanIterated.id),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateLoan(loan: Loan): Promise<Loan | null> {
    try {
      const loanUpdated: Loan = await lastValueFrom(this.loanHttpService.updateLoan(loan));
      this.setState({
        loans: this.state.loans.map((loanIterated: Loan) => (loan.id === loanIterated.id ? loanUpdated : loanIterated)),
      });

      return loanUpdated;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async addLoan(companyId: number): Promise<Loan | null> {
    try {
      const loanAdded: Loan = await lastValueFrom(this.loanHttpService.addLoan(companyId));
      this.setState({
        loans: [...(this.state?.loans || []), loanAdded],
      });

      return loanAdded;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async addLoanAttachments(loan: Loan, files: FileList): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) => lastValueFrom(this.loanHttpService.addLoanAttachment(loan, file))),
      );

      this.setState({
        loans: this.state.loans.map((loanIterated: Loan) =>
          loanIterated.id === loan.id
            ? {
                ...loan,
                attachments: [...loan.attachments, ...attachments],
              }
            : loanIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deleteLoanAttachments(loan: Loan, attachment: Attachment): Promise<void> {
    try {
      await lastValueFrom(this.loanHttpService.removeLoanAttachment(loan, attachment));
      this.setState({
        loans: this.state.loans.map((loadIterated: Loan) =>
          loadIterated.id === loan.id
            ? {
                ...loan,
                attachments: loan.attachments.filter(
                  (attachmentIterated: Attachment) => attachmentIterated.id !== attachment.id,
                ),
              }
            : loadIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
